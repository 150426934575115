const OutstandingCaptain = 'https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingCaptain.png'
const OutstandingTeam = 'https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingTeam.png'
const HonorableCaptain = 'https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableCaptain.png'
const HonorableTeam = 'https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableTeam.png'
const EarlySettlersLv1 = 'https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv1.png'
const EarlySettlersLv2 = 'https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv2.png'
const EarlySettlersLv3 = 'https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv3.png'
const StarStuddedCaptain = 'https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/StarStuddedCaptain.png'


const OutstandingCaptainAddress = "0x650D0bDe3fB6eFA668f416B598A517772021A533";
const OutstandingTeamAddress = "0x0b2cAf1f2b3644e657b97E2Abb6AD7D89e45fce8";
const HonorableCaptainAddress = "0x3c01716cD3a5F7D3e7AdA9f132F45a2F1342f5FA";
const HonorableTeamAddress = "0x88c4fB942517a4FA3C92aC96c71F3dDBED2444B0";
const EarlySettlersLv1Address = "0xA1e40E250a2560ac98e1190952Ced940b11F1524";
const EarlySettlersLv2Address = "0xe2C103a59f1A5E574dFF36Ac22344672D37800bc";
const EarlySettlersLv3Address = "0xF60aD1EcFb2Cd19E7Be2C812fdC7Bd415528fF08";
const StarStuddedCaptainAddress = "0xadE6147319C9B1Aa465Ee31374Acc5E251F1f03E";



export default {
	'outstandingcaption': {
		"ID": 'outstandingcaption',
		"BTcontract": OutstandingCaptainAddress,
		"SBTTokenID": '',
		"SBTIntro": "Exclusive Medal for Top 100 Captain",
		"SBTName": 'Outstanding Captain',
		"SBTSupply": "100",
		"MintFee": "Free",
		"MintDate": "wait for for the announcement",
		"Icon": OutstandingCaptain,
		"MintRequest": "When the Bridge Campaign ends, you will be the captain of the Top 100 team",
		"isStart": false,
		"isAirdrop": true,
	},
	'outstandingteam': {
		"ID": 'outstandingteam',
		"BTcontract": OutstandingTeamAddress,
		"SBTTokenID": '',
		"SBTIntro": "Exclusive Medal for Top 100 team member",
		"SBTName": 'Outstanding Team',
		"SBTSupply": "2400",
		"MintFee": "Free",
		"MintDate": "wait for for the announcement",
		"Icon": OutstandingTeam,
		"MintRequest": "When the Bridge Campaign ends, you are a member of the top 100 team",
		"isStart": false,
		"isAirdrop": true,
	},
	'honorablecaption': {
		"ID": 'honorablecaption',
		"BTcontract": HonorableCaptainAddress,
		"SBTTokenID": '',
		"SBTIntro": "Exclusive Medal for Captain",
		"SBTName": 'Honorable Captain',
		"SBTSupply": "1,000,000",
		"MintFee": "Free",
		"MintDate": "01/22/2024 ~ 01/22/2025",
		"Icon": HonorableCaptain,
		"MintRequest": "You can mint the medal when your team achieves 150,000 points",
		"isStart": false,
		"isAirdrop": true,
	},
	'honorableteam': {
		"ID": 'honorableteam',
		"BTcontract": HonorableTeamAddress,
		"SBTTokenID": '',
		"SBTIntro": "Exclusive Medal for Team Member",
		"SBTName": 'Honorable Team',
		"SBTSupply": "25,000,000",
		"MintFee": "Free",
		"MintDate": "01/22/2024 ~ 01/22/2025",
		"Icon": HonorableTeam,
		"MintRequest": "You can mint the medal when your team achieves 150,000 points",
		"isStart": false,
		"isAirdrop": true,
	},
	'setterv1': {
		"ID": 'setterv1',
		"BTcontract": EarlySettlersLv1Address,
		"SBTTokenID": '',
		"SBTIntro": "Medal of individual bridge campaign",
		"SBTName": 'Early Settlers Lv.1',
		"SBTSupply": "10,000,000",
		"MintFee": "Free",
		"MintDate": "01/22/2024 ~ 01/22/2025",
		"Icon": EarlySettlersLv1,
		"MintRequest": "You can mint the medal when you bridge more than $10 assets",
		"isStart": true,
		"isAirdrop": true,
	},
	'setterv2': {
		"ID":'setterv2',
		"BTcontract": EarlySettlersLv2Address,
		"SBTTokenID": '',
		"SBTIntro": "Medal of individual bridge campaign",
		"SBTName": 'Early Settlers Lv.2',
		"SBTSupply": "10,000,000",
		"MintFee": "Free",
		"MintDate": "01/22/2024 ~ 01/22/2025",
		"Icon": EarlySettlersLv2,
		"MintRequest": "You can mint the medal when you bridge more than $100 assets",
		"isStart": true,
		"isAirdrop": true,
	},
	'setterv3': {

		"ID": 'setterv3',
		"BTcontract": EarlySettlersLv3Address,
		"SBTTokenID": '',
		"SBTIntro": "Exclusive medal of individual bridge campaign",
		"SBTName": 'Early Settlers Lv.3',
		"SBTSupply": "10,000,000",
		"MintFee": "Free",
		"MintDate": "01/22/2024 ~ 01/22/2025",
		"Icon": EarlySettlersLv3,
		"MintRequest": "You can mint the medal when you bridge more than $500 assets",
		"isStart": true,
		"isAirdrop": true,
	},
	'starstuddedcaption': {
		"ID": 'starstuddedcaption',
		"BTcontract": StarStuddedCaptainAddress,
		"SBTTokenID": '',
		"SBTIntro": "Medal for your significant invitation",
		"SBTName": 'Star-Studded Captain',
		"SBTSupply": "1,000,000",
		"MintFee": "Free",
		"MintDate": "01/22/2024 ~ 01/22/2025",
		"Icon": StarStuddedCaptain,
		"MintRequest": "When you invite a significant amount of members, you can mint the medal",
		"isStart": false,
		"isAirdrop": true,
	},
}