<template>
  <div class="all-medal-wall">
    <img class="static-bg-1" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/bg-1.webp" alt="">
    <Header></Header>
    <AllMedalContent></AllMedalContent>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AllMedalContent from './AllMedalContent.vue'

export default {
  name: 'AllMedalWall',
  components: {
    Header,
    Footer,
    AllMedalContent,
  },
}
</script>
<style lang="scss" scoped>
.all-medal-wall {
  color: #fff;
  position: relative;
  
  .static-bg-1 {
    position: absolute;
    top: -99px;
    left: 0;
    z-index: 0;
  }
}
</style>