<template>
	<div class="all-medal-content md:flex-col md:gap-16">
		<div class="content-menu md:w-full md:min-w-full md:max-w-full md:flex-row">
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 md:rounded-full"
				:class="{ 'menu-item-active': active === 'all' }" @click="changeActive('all')">
				<img v-show="active !== 'all'" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/all.svg" alt="">
				<img v-show="active === 'all'" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/all-active.svg" alt="">
				<span>All</span>
			</div>
			<div class="menu-item md:w-auto md:px-20 md:py-2 md:h-36 md:rounded-full"
				:class="{ 'menu-item-active': active === 'owned' }" @click="changeActive('owned')">
				<img v-show="active !== 'owned'" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/owned.svg" alt="">
				<img v-show="active === 'owned'" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/owned-active.svg" alt="">
				<span>Owned</span>
			</div>
		</div>
		<div class="content-main md:grid md:grid-cols-2">
			<template v-for="(item, index) in medalList" >
				<medalCard :item="item"></medalCard>
			</template>
			
		</div>
		</div>
</template>
<script>
	import {  getConfig } from '@/utils/active.api.js'
	import { mapState } from 'vuex'
	import medalCard from '../../components/allMedalWall/medalCard.vue';
	import medalList from './medalList.js'
	export default {
		components: { medalCard},

		data() {
			return {
				popupItem: {},
				active: 'all',
				
			};
		},
		computed: {
			...mapState(['isConnect', 'address', 'sbtMedal']),
			medalList() {
				console.log(medalList)
				
				if(this.active == 'all'){
					return medalList
				}else{
					let a = [];
					for (let item in medalList) {
						if(medalList[item] == '2'){
							a.push(medalList[item])
						}
					}
					return a 
				}
			},
		},
		created() {
			this.active = this.$route.query.type || 'all'
			this.getMedalWall()
		},
		mounted() {
			this.init()
		},
		watch: {
			'$route.query': {
				handler(newQuery, oldQuery) {
					if (this.$route.query.type) {
						this.active = this.$route.query.type
						this.getMedalWall()
					}
					const currentRoute = this.$route;
					if (currentRoute.query.type) {
						this.$router.replace({
							query: {} // 更新后的查询参数对象
						});
					}
				},
				immediate: true, // 是否立即执行回调
				deep: true, // 是否深度监听
			},
		},
		methods: {
			init(){
				getConfig();
				
			},
			changeActive(active) {
				this.active = active;
			},
			
			getMedalWall() {
				console.log(this.active)
			}
		},
	}
</script>
<style lang="scss" scoped>
	.all-medal-content {
		/* max-width: 1590px; */
		margin: auto;
		overflow: hidden;
		position: relative;
		z-index: 1;
		min-height: calc(100vh - 80px);
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 50px 75px 80px;
		display: flex;
		gap: 40px;

		@screen md {
			padding: 16px;
		}

		.content-menu {
			/* margin-right: 40px; */
			width: 196px;
			min-width: 196px;
			max-width: 196px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;

			.menu-item {
				cursor: pointer;
				width: 100%;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 12px;
				border-radius: 8px;
				transition: all .3s;

				img {
					width: 24px;
					margin-right: 16px;
				}

				span {
					display: inline-block;
					color: #A2A3A5;
					font-size: 16px;
					font-style: normal;
					font-weight: 300;
					line-height: 16px;
					/* 100% */
					letter-spacing: -0.4px;
				}
			}

			.menu-item-active {
				background: rgba(255, 255, 255, 0.10);

				span {
					color: #fff;
				}
			}
		}

		.content-main {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			gap: 24px;

			.sbt-item {
				width: 283px;
				/* height: 336px; */
				border-radius: 16px;
				border: 1px solid rgba(255, 255, 255, 0.10);
				background: #1A1A1D;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				position: relative;
				z-index: 0;

				.isAcquire {
					position: absolute;
					right: 5%;
					top: 5%;
					width: 38px;
					height: 38px;

					@screen md {
						width: 28px;
						height: 28px;

					}
				}

				.nft-img {
					border-radius: 16px;
					overflow: hidden;
					width: 281px;
					/* height: 281px; */
					/* min-height: 281px;
					max-height: 281px; */

					img {
						border-radius: 16px;
						width: 100%;
						/* height: 100%; */
						object-fit: cover;
					}
				}

				.nft-name {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 54px;

					.isKt {
						margin-right: 10px;
						width: 24px;
						height: 24px;
					}

					span {
						color: rgba(255, 255, 255, 0.80);
						text-align: center;
						font-size: 14px;
						font-style: normal;
						font-weight: 590;
						line-height: 14px;
						/* 100% */
						letter-spacing: -0.4px;
					}
				}

				.nft-name-md {
					display: none;
					color: #fff;

					.nft-detail-md {
						padding: 0 8px 8px;

						h1 {
							.isKt {
								margin-right: 10px;
								width: 24px;
								height: 24px;
							}

							color: rgba(255, 255, 255, 0.80);
							font-size: 14px;
							font-weight: 700;
							line-height: 16px;
							/* 100% */
							letter-spacing: -0.4px;
						}

						p {
							margin: 0;
							color: rgba(255, 255, 255, 0.80);
							font-size: 12px;
							font-weight: 400;
							line-height: 16px;
							/* 133.333% */
							letter-spacing: -0.8px;
						}
					}

					.nft-claim-md {
						width: 100%;
						height: 36px;
						display: flex;
						align-items: center;
						justify-content: center;

						span {
							display: inline-block;
							width: 100%;
							height: 100%;
							text-align: center;
							line-height: 36px;
							border-radius: 0px 0px 16px 16px;
							border: 1px solid rgba(255, 255, 255, 0.10);
							background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
							border: none;
						}
					}
				}

				.detail-btn {
					width: 100%;
					position: absolute;
					bottom: -142px;
					left: 0;
					z-index: 2;
					overflow: hidden;
					transition: all .3s;

					.detail-box {
						height: 100px;
						padding: 16px 12px;
						border: 1px solid rgba(255, 255, 255, 0.10);
						border-radius: 16px 16px 0px 0px;
						border-left: none;
						border-right: none;
						border-bottom: none;
						background: rgba(255, 255, 255, 0.09);
						backdrop-filter: blur(15px);

						span {
							color: #FFF;
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 14px;
							border: none;
							/* 100% */
						}

						p {
							margin: 0;
							margin-top: 10px;
							color: rgba(255, 255, 255, 0.80);
							font-size: 14px;
							font-style: normal;
							font-weight: 300;
							line-height: 18px;
							/* 128.571% */
						}
					}

					.btn-box {
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 42px;
						border-radius: 0px 0px 16px 16px;
						background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

						/* backdrop-filter: blur(15px); */
						span {
							display: inline-block;
							width: 100%;
							height: 100%;
							color: #FFF;
							text-align: center;
							font-size: 14px;
							font-style: normal;
							font-weight: 590;
							line-height: 42px;
							/* 100% */
							letter-spacing: -0.4px;
						}
					}
				}

				&:hover {
					.detail-btn {
						bottom: 0;
						/* transform: translateY(0); */
					}
				}
			}
		}
	}
</style>